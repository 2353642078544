import {
  addCurrentUserEventListener,
  getCurrentUser,
  removeCurrentUserEventListener,
  setUserClaims,
  UserPublicInfo
} from '../../webmodule-common/other/api/current-user';
import { appConfigMenuPageItems } from './app-config';
import { AppIndex, MenuItem } from '../../webmodule-common/other/app-index';
import { applyDebugInformation } from './debug';
import { attachRouter } from '../../webmodule-common/other/router';
import { ConnectedState } from '../api/misc-codegen';
import { currentUserClaims } from '../../webmodule-common/other/currentuser-claims';
import { customElement, query } from 'lit/decorators.js';
import { firstValidString, isEmptyOrSpace } from '../../webmodule-common/other/ui/string-helper-functions';
import { FormInputAssistantBase } from '../../webmodule-common/other/webmodule-common';
import { getApi, getApiFactory } from '../api/api-injector';
import { getAssetCdnPath } from '../../webmodule-common/other/common/assets';
import { getPageController } from '../../webmodule-common/other/ui/pages/page-base';
import { html, TemplateResult } from 'lit';
import { iconRegistry } from '../../webmodule-common/other/ui/icons/register-icons';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { injectThemeClasses, isTestSite } from '../../webmodule-common/other/domain';
import { noSqlDb, NoSqlDb } from '../../webmodule-common/database/no-sql-db';
import { rebindRouter } from './application-initialization';
import { registerIconLibrary } from '../../webmodule-common/components/src/webmodule-components';
import { supplierComponentRegistry } from './componentRegistry';
import { tlang } from '../../webmodule-common/other/language/lang';
import { userDataStore } from './common/current-user-data-store';
import { verifySession } from '../../webmodule-common/other/user-session-verifier';
import { webcomponentRegistry } from '../../webmodule-common/other/ui/webcomponent-registry';

// this is our concrete top level application, everything else should be generic
@customElement('wm-supplier-app-index')
export class WebModuleSupplierAppIndex extends AppIndex {
  protected noSqlDb: NoSqlDb = noSqlDb;

  // Do not remove below line as this will stop icons from working on our site.
  protected icons = iconRegistry;
  protected webcomponents = webcomponentRegistry;
  protected supplierComponents = supplierComponentRegistry;
  @query('#main-body')
  private mainBody!: HTMLElement;

  constructor() {
    super();
    applyDebugInformation();
    rebindRouter();
    console.log(this.noSqlDb.dbName());
    registerIconLibrary('system', {
      resolver: name => `/assets/icons/${name}.svg`
    });

    registerIconLibrary('bootstrap', {
      resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/icons/${name}.svg`
    });

    registerIconLibrary('fa', {
      resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.substring(0, 4) === 'fas-') folder = 'solid';
        if (name.substring(0, 4) === 'fab-') folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.2/svgs/${folder}/${filename}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });

    this.userDisplayName = getCurrentUser()?.friendlyName;
  }

  async userStateChanged(_user: UserPublicInfo | null) {
    if (getCurrentUser() !== null) {
      await this.updateUIAspectsAfterLogin();
    } else this.userDisplayName = '';
  }

  generateBuildNumberText(): TemplateResult {
    const copyEvent = async () => {
      const text = `# Dealer's Module (SoftTech dealer CPQ)
      
+ ${tlang`Location`} "${window.location.hostname}"     
+ ${tlang`Dealer Build "${this.buildNumber}`}"

            `;
      navigator?.clipboard?.writeText(text);
      await information(text, tlang`Copied to clipboard`);
    };

    const forms = new FormInputAssistantBase(false);
    return html`${forms.clickableIcon({
      library: 'system',
      name: 'db-version',
      events: { click: copyEvent }
    })}`;
  }

  connectedCallback(): void {
    super.connectedCallback();
    addCurrentUserEventListener(this);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    removeCurrentUserEventListener(this);
  }

  userNameMenuContent(): unknown {
    return html` <webmodule-icon library="fa" name="fas-user" slot="prefix"></webmodule-icon
      >${this.userDisplayName?.trim()}`;
  }

  globalSupportInformation(): string | TemplateResult {
    const cu = getCurrentUser();
    const cc = currentUserClaims();
    if (cc.isCynclyStaff) {
      return `CYNCLY Support connected to "${(cu as any).tenantName}"`;
    }
    return '';
  }

  customUserMenuElements(): TemplateResult {
    return html``;
  }

  async disconnectUser() {
    await getPageController().disposeAndRefresh();
    try {
      await getApi().post<ConnectedState>('api/Supplier/DisconnectUser', {});
      setUserClaims({});
    } catch {
      console.log(`Error disconnecting user`);
    }
    return true;
  }

  protected getMenuItems(): Array<MenuItem> {
    return appConfigMenuPageItems();
  }

  protected firstUpdated() {
    attachRouter(this.mainBody);
    let interval;
    const verifyUser = async () => {
      clearInterval(interval);
      await verifySession();
      interval = setInterval(verifyUser, 500);
    };
    interval = setInterval(verifyUser, 500);
    injectThemeClasses();
  }

  protected updateLogoTemplate() {
    if (this.outOfDate) return;
    const api = getApiFactory().blob();
    const positivelogoURl = isEmptyOrSpace(userDataStore.supplierSettings.positiveLogoVirtualPath)
      ? getAssetCdnPath('./assets/images/sample-logo-colour.png')
      : api.fullUrl(userDataStore.supplierSettings.positiveLogoVirtualPath);
    const negativelogoURl = isEmptyOrSpace(userDataStore.supplierSettings.negativeLogoVirtualPath)
      ? getAssetCdnPath('./assets/images/sample-logo-white.png')
      : api.fullUrl(userDataStore.supplierSettings.negativeLogoVirtualPath);
    const logoURl = isTestSite() ? negativelogoURl : positivelogoURl;
    this.logoTemplate = logoURl
      ? html`<img src=${logoURl} crossorigin="anonymous" class="img-fluid" alt="Brand" width="90" />`
      : html` <svg width="1" height="1"></svg>`;
  }

  private async updateUIAspectsAfterLogin() {
    await userDataStore.loadCoreDetailsAfterLogin();
    this.updateLogoTemplate();
    this.userDisplayName = getCurrentUser()?.friendlyName;
    this.requestUpdate();
  }
}
